exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-autotel-js": () => import("./../../../src/pages/components/Autotel.js" /* webpackChunkName: "component---src-pages-components-autotel-js" */),
  "component---src-pages-components-goto-js": () => import("./../../../src/pages/components/Goto.js" /* webpackChunkName: "component---src-pages-components-goto-js" */),
  "component---src-pages-components-info-js": () => import("./../../../src/pages/components/Info.js" /* webpackChunkName: "component---src-pages-components-info-js" */),
  "component---src-pages-components-input-with-label-js": () => import("./../../../src/pages/components/InputWithLabel.js" /* webpackChunkName: "component---src-pages-components-input-with-label-js" */),
  "component---src-pages-components-place-input-js": () => import("./../../../src/pages/components/PlaceInput.js" /* webpackChunkName: "component---src-pages-components-place-input-js" */),
  "component---src-pages-components-ride-result-js": () => import("./../../../src/pages/components/RideResult.js" /* webpackChunkName: "component---src-pages-components-ride-result-js" */),
  "component---src-pages-components-settings-box-js": () => import("./../../../src/pages/components/SettingsBox.js" /* webpackChunkName: "component---src-pages-components-settings-box-js" */),
  "component---src-pages-components-share-js": () => import("./../../../src/pages/components/Share.js" /* webpackChunkName: "component---src-pages-components-share-js" */),
  "component---src-pages-components-taxi-js": () => import("./../../../src/pages/components/Taxi.js" /* webpackChunkName: "component---src-pages-components-taxi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

